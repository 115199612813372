import { render, staticRenderFns } from "./MultiManuscriptEditorPage.vue?vue&type=template&id=60e000f4&scoped=true"
import script from "./MultiManuscriptEditorPage.vue?vue&type=script&lang=ts"
export * from "./MultiManuscriptEditorPage.vue?vue&type=script&lang=ts"
import style0 from "./MultiManuscriptEditorPage.vue?vue&type=style&index=0&id=60e000f4&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60e000f4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/satoru/dev/Nola/nola-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('60e000f4')) {
      api.createRecord('60e000f4', component.options)
    } else {
      api.reload('60e000f4', component.options)
    }
    module.hot.accept("./MultiManuscriptEditorPage.vue?vue&type=template&id=60e000f4&scoped=true", function () {
      api.rerender('60e000f4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/novel/MultiManuscriptEditorPage.vue"
export default component.exports