var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "multi-manuscript-editor-footer" }, [
    _c("div", { staticClass: "input-show-tabs" }, [
      !_vm.isShowPreview
        ? _c("div", { staticClass: "tab-inputs" }, [
            _c(
              "div",
              {
                staticClass: "tab-input",
                class: { disabled: !_vm.canSelectFlag("plots") },
                on: {
                  click: function ($event) {
                    _vm.canSelectFlag("plots") && _vm.onChangeShowFlags("plots")
                  },
                },
              },
              [
                _c("checkbox-icon", {
                  attrs: {
                    isChecked: _vm.isShowPlots,
                    fill: _vm.checkBoxColor,
                  },
                }),
                _c(
                  "label",
                  {
                    staticClass: "is-show-label",
                    class: { disabled: !_vm.canSelectFlag("plots") },
                  },
                  [_vm._v("プロット")]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "tab-input",
                class: { disabled: !_vm.canSelectFlag("characters") },
                on: {
                  click: function ($event) {
                    _vm.canSelectFlag("characters") &&
                      _vm.onChangeShowFlags("characters")
                  },
                },
              },
              [
                _c("checkbox-icon", {
                  attrs: {
                    isChecked: _vm.isShowCharacters,
                    fill: _vm.checkBoxColor,
                  },
                }),
                _c(
                  "label",
                  {
                    staticClass: "is-show-label",
                    class: { disabled: !_vm.canSelectFlag("characters") },
                  },
                  [_vm._v("登場人物")]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "tab-input",
                class: { disabled: !_vm.canSelectFlag("worldViews") },
                on: {
                  click: function ($event) {
                    _vm.canSelectFlag("worldViews") &&
                      _vm.onChangeShowFlags("worldViews")
                  },
                },
              },
              [
                _c("checkbox-icon", {
                  attrs: {
                    isChecked: _vm.isShowWorldViews,
                    fill: _vm.checkBoxColor,
                  },
                }),
                _c(
                  "label",
                  {
                    staticClass: "is-show-label",
                    class: { disabled: !_vm.canSelectFlag("worldViews") },
                  },
                  [_vm._v("世界観")]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "tab-input",
                class: { disabled: !_vm.canSelectFlag("memo") },
                on: {
                  click: function ($event) {
                    _vm.canSelectFlag("memo") && _vm.onChangeShowFlags("memo")
                  },
                },
              },
              [
                _c("checkbox-icon", {
                  attrs: { isChecked: _vm.isShowMemo, fill: _vm.checkBoxColor },
                }),
                _c(
                  "label",
                  {
                    staticClass: "is-show-label",
                    class: { disabled: !_vm.canSelectFlag("memo") },
                  },
                  [_vm._v("メモ")]
                ),
              ],
              1
            ),
          ])
        : _c("div", { staticClass: "tab-inputs" }, [
            _c(
              "div",
              {
                staticClass: "tab-input",
                on: {
                  click: function ($event) {
                    return _vm.onChangeShowFlags("plots")
                  },
                },
              },
              [
                _c("radio-icon", {
                  attrs: {
                    value: "plots",
                    selected: _vm.selectedRadio,
                    fill: _vm.checkBoxColor,
                  },
                }),
                _c(
                  "label",
                  {
                    staticClass: "is-show-label",
                    attrs: { for: "isShowPlotsRadio" },
                  },
                  [_vm._v("プロット")]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "tab-input",
                on: {
                  click: function ($event) {
                    return _vm.onChangeShowFlags("characters")
                  },
                },
              },
              [
                _c("radio-icon", {
                  attrs: {
                    value: "characters",
                    selected: _vm.selectedRadio,
                    fill: _vm.checkBoxColor,
                  },
                }),
                _c(
                  "label",
                  {
                    staticClass: "is-show-label",
                    attrs: { for: "isShowCharactersRadio" },
                  },
                  [_vm._v("登場人物")]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "tab-input",
                on: {
                  click: function ($event) {
                    return _vm.onChangeShowFlags("worldViews")
                  },
                },
              },
              [
                _c("radio-icon", {
                  attrs: {
                    value: "worldviews",
                    selected: _vm.selectedRadio,
                    fill: _vm.checkBoxColor,
                  },
                }),
                _c(
                  "label",
                  {
                    staticClass: "is-show-label",
                    attrs: { for: "isShowWorldViewsRadio" },
                  },
                  [_vm._v("世界観")]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "tab-input",
                on: {
                  click: function ($event) {
                    return _vm.onChangeShowFlags("memo")
                  },
                },
              },
              [
                _c("radio-icon", {
                  attrs: {
                    value: "memo",
                    selected: _vm.selectedRadio,
                    fill: _vm.checkBoxColor,
                  },
                }),
                _c(
                  "label",
                  {
                    staticClass: "is-show-label",
                    attrs: { for: "isShowMemoRadio" },
                  },
                  [_vm._v("メモ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "tab-input",
                on: {
                  click: function ($event) {
                    return _vm.onChangeShowFlags(null)
                  },
                },
              },
              [
                _c("radio-icon", {
                  attrs: {
                    value: "",
                    selected: _vm.selectedRadio,
                    fill: _vm.checkBoxColor,
                  },
                }),
                _c(
                  "label",
                  {
                    staticClass: "is-show-label",
                    attrs: { for: "isHiddenRadio" },
                  },
                  [_vm._v("非表示")]
                ),
              ],
              1
            ),
          ]),
      _c(
        "div",
        {
          staticClass: "show-preview",
          class: { isShowPreview: _vm.isShowPreview },
          on: { click: _vm.onClickPreview },
        },
        [_c("text-vertical-icon", { attrs: { isActive: _vm.isShowPreview } })],
        1
      ),
    ]),
    _c("div", { staticClass: "buttons" }, [
      _c(
        "button",
        { staticClass: "button close", on: { click: _vm.onClickCloseButton } },
        [_vm._v("閉じる")]
      ),
      _c(
        "button",
        {
          staticClass: "button save",
          attrs: { disabled: !_vm.changed },
          on: { click: _vm.onClickSave },
        },
        [_vm._v("保存する")]
      ),
      _c("div", { staticClass: "status-wrapper" }, [
        _c("div", { staticClass: "save-status", class: { saved: _vm.saved } }, [
          _vm._v(_vm._s(_vm.saveStatus)),
        ]),
        _c("div", { staticClass: "last-modified" }, [
          _vm._v(_vm._s(_vm.lastModified)),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "setting-container" },
      [
        _c("dropup-menu", {
          staticClass: "dropup-menu",
          attrs: {
            title: _vm.countTypeName,
            content: _vm.wordCountInfo,
            items: _vm.countTypeList,
            styleObject: { backgroundColor: "#EDEDED" },
          },
        }),
        _c("hamburger-menu", {
          staticClass: "hamburger-menu",
          attrs: {
            items: _vm.hamburgerMenuItems,
            togglableItems: _vm.hamburgerMenuTogglableItems,
            styleObject: { backgroundColor: "#EDEDED" },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }