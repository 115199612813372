var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "plot-list-vertical" },
    [
      _c(
        "div",
        {
          staticClass: "plot-item",
          on: {
            click: function ($event) {
              return _vm.onClickPlot("default")
            },
          },
        },
        [_vm._v("全体プロット")]
      ),
      _vm._l(_vm.subPlots, function (item) {
        return _c(
          "div",
          {
            key: item.plotId,
            staticClass: "plot-item",
            on: {
              click: function ($event) {
                return _vm.onClickPlot(item.plotId)
              },
            },
          },
          [_vm._v(" " + _vm._s(item.name) + " ")]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }