var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "multi-manuscript-editor" }, [
    _c("div", { staticClass: "main-area", style: _vm.styleGridMainArea }, [
      _vm.isShowPlots
        ? _c("div", { staticClass: "plot", style: _vm.styleGridPlot }, [
            _c("div", { staticClass: "tab-title" }, [_vm._v("プロット一覧")]),
            _c(
              "div",
              { staticClass: "plot-container" },
              [
                _vm.selectedPlotId
                  ? [
                      _c(
                        "div",
                        {
                          staticClass: "back-to-list",
                          on: { click: _vm.onClickBackToPlotList },
                        },
                        [_vm._v("＜ 一覧に戻る")]
                      ),
                      _vm.selectedPlotId
                        ? _c("plots", {
                            attrs: {
                              novelId: _vm.novelId,
                              plot: _vm.plot,
                              isMulti: true,
                            },
                            on: { clickChildPlot: _vm.onClickPlotItem },
                          })
                        : _vm._e(),
                    ]
                  : _c("plot-list-vertical", {
                      attrs: { subPlots: _vm.subPlots },
                      on: { click: _vm.onClickPlotItem },
                    }),
              ],
              2
            ),
          ])
        : _vm._e(),
      _vm.isShowCharacters
        ? _c(
            "div",
            { staticClass: "characters", style: _vm.styleGridCharacters },
            [
              _c("div", { staticClass: "tab-title" }, [_vm._v("登場人物一覧")]),
              _c(
                "div",
                { staticClass: "character-container" },
                [
                  _vm.characterFolders && _vm.isShowCharacterFolderList
                    ? _c("character-folder-list", {
                        attrs: {
                          novelId: _vm.novelId,
                          items: _vm.characterFolders,
                          charactersCount: _vm.charactersCount,
                          selectedFolderId: _vm.selectedCharacterFolderId,
                        },
                        on: { selectedFolder: _vm.onClickCharacterFolder },
                      })
                    : _vm._e(),
                  _vm.characters &&
                  !_vm.isShowCharacterFolderList &&
                  !_vm.selectedCharacter
                    ? _c("character-list", {
                        attrs: {
                          novelId: _vm.novelId,
                          characters: _vm.characters,
                          folder: _vm.selectedCharacterFolder,
                          "on-click-item": _vm.onClickCharacterItem,
                          needsDoCustomClickFunction: true,
                        },
                        on: {
                          backToFolderList:
                            _vm.onClickBackToCharacterFolderList,
                        },
                      })
                    : _vm._e(),
                  _vm.characters &&
                  !_vm.isShowCharacterFolderList &&
                  _vm.selectedCharacter
                    ? _c(
                        "div",
                        {
                          staticClass: "back-to-list",
                          on: { click: _vm.onClickBackToCharacterList },
                        },
                        [_vm._v(" ＜ 一覧に戻る ")]
                      )
                    : _vm._e(),
                  _vm.characters && _vm.selectedCharacter
                    ? _c("character-detail", {
                        attrs: {
                          novelId: _vm.novelId,
                          character: _vm.selectedCharacter,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.isShowWorldViews
        ? _c(
            "div",
            { staticClass: "worldviews", style: _vm.styleGridWorldViews },
            [
              _c("div", { staticClass: "tab-title" }, [_vm._v("世界観一覧")]),
              _c(
                "div",
                { staticClass: "worldview-container" },
                [
                  _vm.worldViewFolders && _vm.isShowWorldViewFolderList
                    ? _c("world-view-folder-list", {
                        attrs: {
                          novelId: _vm.novelId,
                          items: _vm.worldViewFolders,
                          worldViewsCount: _vm.worldViewsCount,
                          selectedFolderId: _vm.selectedWorldViewFolderId,
                        },
                        on: { selectedFolder: _vm.onClickWorldViewFolder },
                      })
                    : _vm._e(),
                  _vm.worldViews &&
                  !_vm.isShowWorldViewFolderList &&
                  !_vm.selectedWorldView
                    ? _c("world-view-list", {
                        attrs: {
                          novelId: _vm.novelId,
                          worldViews: _vm.worldViews,
                          folder: _vm.selectedWorldViewFolder,
                          "on-click-item": _vm.onClickWorldViewItem,
                          needsDoCustomClickFunction: true,
                        },
                        on: {
                          backToFolderList:
                            _vm.onClickBackToWorldViewFolderList,
                        },
                      })
                    : _vm._e(),
                  _vm.worldViews &&
                  !_vm.isShowWorldViewFolderList &&
                  _vm.selectedWorldView
                    ? _c(
                        "div",
                        {
                          staticClass: "back-to-list",
                          on: { click: _vm.onClickBackToWorldViewList },
                        },
                        [_vm._v(" ＜ 一覧に戻る ")]
                      )
                    : _vm._e(),
                  _vm.worldViews &&
                  !_vm.isShowWorldViewFolderList &&
                  _vm.selectedWorldView
                    ? _c("world-view-detail", {
                        attrs: {
                          novelId: _vm.novelId,
                          "world-view": _vm.selectedWorldView,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.isShowMemo
        ? _c("div", { staticClass: "memo", style: _vm.styleGridMemo }, [
            _c("div", { staticClass: "tab-title" }, [_vm._v("メモ")]),
            _c(
              "div",
              { staticClass: "memo-container" },
              [
                !_vm.selectedMemoId
                  ? [
                      _c("memo-list", {
                        staticClass: "memo-list",
                        attrs: {
                          novelId: _vm.novelId,
                          onClickItem: _vm.onClickMemoItem,
                        },
                      }),
                    ]
                  : [
                      _c(
                        "div",
                        {
                          staticClass: "back-to-list",
                          on: { click: _vm.onClickBackToMemoList },
                        },
                        [_vm._v("＜ 一覧に戻る")]
                      ),
                      _c("memo-detail", {
                        staticClass: "memo-detail",
                        attrs: {
                          novelId: _vm.novelId,
                          memoId: _vm.selectedMemoId,
                          inNovel: true,
                          readonly: "",
                        },
                      }),
                    ],
              ],
              2
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "manuscript-wrapper",
          class: _vm.theme,
          style: _vm.styleGridManuscript,
        },
        [
          _c(
            "div",
            [
              _c(
                "div",
                {
                  staticClass: "manuscript-area",
                  class: {
                    isShowPreview: _vm.isShowPreview,
                    isShowEditorController: _vm.isShowEditorController,
                  },
                },
                [
                  _c("editor", {
                    attrs: { title: _vm.title, content: _vm.content },
                    on: {
                      changeTitle: _vm.onChangeTitle,
                      changeContent: _vm.onChangeContent,
                      compositionStart: _vm.onStartComposition,
                      compositionEnd: _vm.onEndComposition,
                    },
                  }),
                  _vm.isShowPreview
                    ? _c("manuscript-preview", {
                        staticClass: "preview",
                        attrs: {
                          novelId: _vm.novelId,
                          manuscriptList: _vm.manuscriptList,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm.isShowEditorController
                ? _c("editor-controller", {
                    staticClass: "controller",
                    class: { isShowPreview: _vm.isShowPreview },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ]),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _c("multi-manuscript-editor-footer", {
          ref: "footer",
          attrs: {
            novelId: _vm.novelId,
            manuscriptKey: _vm.manuscriptKey,
            content: _vm.content,
            changed: _vm.changed,
            contentLength: _vm.contentLength,
            pageCount: _vm.pageCount,
            saved: _vm.saved,
          },
          on: { close: _vm.close, save: _vm.save, restore: _vm.restoreContent },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }