var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "editor-controller" },
    [
      _c("div", { staticClass: "insert-symbol" }, [
        _c("div", { staticClass: "icon-box", on: { click: _vm.brackets } }, [
          _c("div", [_vm._v("「」")]),
        ]),
        _c("div", { staticClass: "icon-box", on: { click: _vm.ellipsis } }, [
          _c("div", [_vm._v("……")]),
        ]),
        _c("div", { staticClass: "icon-box", on: { click: _vm.space } }, [
          _c("img", {
            attrs: {
              src: require("@/assets/img/icon/space_bar.svg"),
              alt: "space bar",
            },
          }),
        ]),
        _c("div", { staticClass: "icon-box", on: { click: _vm.dash } }, [
          _c("div", [_vm._v("――")]),
        ]),
        _c("div", { staticClass: "icon-box", on: { click: _vm.ruby } }, [
          _c("div", [_vm._v("ルビ")]),
        ]),
        _c(
          "div",
          { staticClass: "icon-box", on: { click: _vm.emphasisMark } },
          [_c("div", [_vm._v("傍点")])]
        ),
      ]),
      _c("div", { staticClass: "undo-redo" }, [
        _c(
          "div",
          {
            staticClass: "icon-box",
            on: { click: _vm.toggleEnabledSearchReplace },
          },
          [_vm._v("検索/置換")]
        ),
        _c("div", { staticClass: "icon-box", on: { click: _vm.undo } }, [
          _vm._m(0),
        ]),
        _c("div", { staticClass: "icon-box", on: { click: _vm.redo } }, [
          _vm._m(1),
        ]),
      ]),
      _vm.enabledSearchReplace
        ? _c("SearchReplaceDialog", { staticClass: "dialog" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "icon-text" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/icon/undo.svg"), alt: "undo" },
      }),
      _c("div", [_vm._v("戻る")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "icon-text" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/icon/redo.svg"), alt: "redo" },
      }),
      _c("div", [_vm._v("進む")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }